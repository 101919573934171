import React, { useState, useRef } from "react"
import { ChevronUpIcon } from "@heroicons/react/outline"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { Disclosure, Transition } from "@headlessui/react"
import BpLogo from "./BpLogo"
import Link from "../Link"
import Headroom from "react-headroom"
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick"
import { Squash as Hamburger } from "hamburger-react"
import { MenuEn } from "../data/MenuEn"
import MenuItem from "./MenuItem"
import ExternalLink from "../common/ExternalLink"

export default function HeaderEn({ siteTitle, lang, translation }) {
  const [isExpanded, toggleExpansion] = useState(false)
  const dropdownRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useDetectOutsideClick(
    dropdownRef,
    false
  )
  const [mouseOverButton, setMouseOverButton] = useState(false)
  const [mouseOverMenu, setMouseOverMenu] = useState(false)
  const timeoutDuration = 200
  let timeoutButton
  let timeoutMenu

  const onMouseEnterButton = () => {
    clearTimeout(timeoutButton)
    setOpenDropdown(true)
    setMouseOverButton(true)
  }
  const onMouseLeaveButton = () => {
    timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
  }

  const onMouseEnterMenu = () => {
    clearTimeout(timeoutMenu)
    setMouseOverMenu(true)
  }
  const onMouseLeaveMenu = () => {
    timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
  }

  const show = openDropdown && (mouseOverMenu || mouseOverButton)

  return (
    <Headroom className="z-50 relative">
      <div className="bg-eight topbar block ">
        <div className="max-w-4xl mx-auto px-2 lg:px-8  uppercase tracking-wide rolling-text md:rolling-text-none whitespace-nowrap">
          <span className="font-semibold">24.09.06:&nbsp;</span> We're hiring!{" "}
          <a
            href="https://www.linkedin.com/jobs/view/4017514039/?refId=f8ufb3VrQyi4MfQ0Z2xMOA%3D%3D&trackingId=f8ufb3VrQyi4MfQ0Z2xMOA%3D%3D"
            className="underline"
          >
            Read more
          </a>
        </div>
      </div>
      <div className="relative z-50 bg-primary drop-shadow-header filter">
        <div className="mx-auto px-4 sm:px-6 py-0">
          <div className="flex justify-between items-center md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <div className=" hidden lg:inline-block">
                <div className="w-3">
                  <BpLogo />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center max-w-4xl w-full justify-between lg:px-8">
              <div className="font-semibold tracking-tight whitespace-nowrap">
                <Link to={`${lang === "sv" ? "/" : "/en"}`}> {siteTitle}</Link>
              </div>
              <div className="hidden lg:flex">
                <div className="relative">
                  <div className="flex flex-row items-center">
                    {MenuEn.items.map(menuItem => {
                      return (
                        <MenuItem key={menuItem.title} menuItem={menuItem} />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="-mr-2 -my-2 lg:hidden">
              <div className="py-2 inline-flex items-center justify-center z-50 relative">
                <Hamburger
                  toggled={isExpanded}
                  toggle={toggleExpansion}
                  onClick={() => toggleExpansion(!isExpanded)}
                  size="24"
                  color="#121212"
                />
              </div>
            </div>
            <div className="hidden lg:flex flex-row items-center space-x-2  justify-end flex-1  ">
              <div className="ctrm-offset pb-1 px-2 bg-sixth rounded-md  mr-2 whitespace-nowrap text-xs tracking-wider uppercase">
                <Link to="https://ctrm.bodeckerpartners.com/">CTRM Login</Link>
              </div>
              <ul className="flex flex-row space-x-2 text-xs tracking-wider">
                <li>
                  <Link
                    to={`${translation ? translation : "/"}`}
                    className={`${
                      lang === "sv" ? "border-secondary border-b pb-1" : ""
                    }`}
                  >
                    SV
                  </Link>
                </li>
                <li>
                  <Link
                    to="/en"
                    className={`${
                      lang === "en" ? "border-secondary border-b pb-1" : ""
                    }`}
                  >
                    EN
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } fixed top-0 inset-x-0 transition transform origin-top-right lg:hidden `}
        >
          <div className="bg-primary min-h-screen mobilemenu ">
            <div
              className="flex items-center justify-between px-4 h-full"
              style={{ paddingTop: "10px" }}
            >
              <div className="font-semibold tracking-tight whitespace-nowrap">
                {siteTitle}
              </div>
            </div>
            <div className="pt-2 pb-6 px-5 ">
              <div className="mt-6 flex flex-col justify-between h-full">
                <div>
                  <nav className="space-y-4 flex flex-col justify-start items-start">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="w-full flex flex-row justify-between items-center">
                            <span>For Asset Owners And Investors</span>
                            <div className="w-6">
                              <ChevronUpIcon
                                className={`${
                                  open
                                    ? "transition-all transform rotate-180"
                                    : "transition-all"
                                }`}
                              />
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <div className="px-4">
                              <Link
                                to="/en/services/revenue-management/"
                                className="-m-3 p-4 flex items-center rounded-md "
                              >
                                Revenue Management
                              </Link>
                              <Link
                                to="/en/services/ppa-2/"
                                className="-m-3 p-4 flex items-center rounded-md "
                              >
                                PPA
                              </Link>
                              <Link
                                to="/en/services/advisory/"
                                className="-m-3 p-4 flex items-center rounded-md "
                              >
                                Investment Advisory
                              </Link>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="w-full flex flex-row justify-between items-center">
                            <span>For Power Consumers</span>
                            <div className="w-6">
                              <ChevronUpIcon
                                className={`${
                                  open
                                    ? "transition-all transform rotate-180"
                                    : "transition-all"
                                }`}
                              />
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <div className="px-4">
                              <Link
                                to="/en/services/renewable-power/"
                                className="-m-3 p-4 flex items-center rounded-md "
                              >
                                100% Renewable Power
                              </Link>
                              <Link
                                to="/en/services/corporate-ppa-for-power-consumers/"
                                className="-m-3 p-4 flex items-center rounded-md "
                              >
                                Corporate PPA
                              </Link>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </nav>
                </div>
                <div className="space-y-4 mt-4">
                  <Link to="/en/reports" className="block">
                    Reports
                  </Link>

                  <Link to="/en/about-us" className="block">
                    About us
                  </Link>
                </div>
              </div>
              <div className="py-8 mt-8 border-t-2 border-b-2 border-sixth -mx-8 space-y-4">
                <div className="px-8">For clients with exposure to EU-ETS:</div>
                <div className="px-8 flex flex-row justify-between items-center">
                  <div>
                    <Link to="https://www.bodeckercarbon.com">
                      Bodecker Carbon
                    </Link>
                  </div>
                  <div className="w-5 -mr-1">
                    {" "}
                    <ExternalLink />
                  </div>
                </div>
              </div>
              <div className=" py-8 w-full ">
                <div className="flex flex-row items-center justify-between space-x-2 flex-1  text-sm">
                  <div className="bg-sixth rounded-md py-1 px-2 mr-2 whitespace-nowrap  tracking-wider inline-block uppercase">
                    <Link to="https://ctrm.bodeckerpartners.com/">
                      CTRM Login
                    </Link>
                  </div>
                  <ul className="flex flex-row space-x-2  tracking-wider">
                    <li>
                      <Link
                        to="/"
                        className={`${
                          lang === "sv" ? "border-secondary border-b pb-2" : ""
                        }`}
                      >
                        SV
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/en"
                        className={`${
                          lang === "en" ? "border-secondary border-b pb-2" : ""
                        }`}
                      >
                        EN
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Headroom>
  )
}
